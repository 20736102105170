import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import styled from "@emotion/styled";
import HeaderWithUnderline from "../Configurations/Enquiries/HeaderWithUnderline";
import NameDescription from "../Configurations/Enquiries/NameDescription";

import TransactionTable from "./TransactionTable";
import AmountPayModal from "./AmountPayModal";
import SettlePaymentModal from "./SettlePaymentModal";
import { getTransporterTransactions } from "../../../api/Admin/trips";

export default function TransporterPaymentDetails({
  tripData,
  getTripById,
  handleOpenToast,
}) {
  const [isAmountModalOpen, setIsAmountModalOpen] = useState(false);

  const [showTransaction, setShowTransaction] = useState(false);
  const handleAmountCloseModal = () => {
    setIsAmountModalOpen(false);
    getTripById();
  };

  const [isSettlePaymentModalOpen, setIsSettlePaymentModalOpen] =
    useState(false);

  const [transactionData, setTransactionData] = useState([]);

  const getTransaction = async () => {
    try {
      const response = await getTransporterTransactions(tripData?.tripId);
      if (response.status === 200) {
        setTransactionData(response.data.data);
      }
    } catch (error) {
      handleOpenToast("Error while getting transaction", "error");
    }
  };

  const handleSettlePaymentCloseModal = () => {
    setIsSettlePaymentModalOpen(false);
    getTripById();
  };

  useEffect(() => {
    getTransaction();
  }, [tripData]);

  return (
    <Box sx={{ padding: "24px" }}>
      <AmountPayModal
        isModalOpen={isAmountModalOpen}
        handleCloseModal={handleAmountCloseModal}
        tripData={tripData}
        handleOpenToast={handleOpenToast}
      />
      <SettlePaymentModal
        isModalOpen={isSettlePaymentModalOpen}
        handleCloseModal={handleSettlePaymentCloseModal}
        tripData={tripData}
        userType="transporter"
        handleOpenToast={handleOpenToast}
      />
      <HeaderWithUnderline header=" Transporter Payment Details" />

      <StyledBox>
        <BoxContainer marginLeft="0px" gap="40px">
          <NameDescription
            name="Total Amount"
            description={
              `₹ ${Math.ceil(tripData?.transporterAmounts?.finalBidAmount)}` ||
              "-"
            }
            color="#6D6D6D"
            marginLeft="0px"
          />
          <NameDescription
            name="Amount Paid"
            description={
              `₹ ${Math.ceil(tripData?.transporterAmounts?.amountPaid)}` || "-"
            }
            marginLeft="0px"
            color="green"
          />

          {!tripData?.tripStatus?.includes("CANCELLED") && (
            <NameDescription
              name="Balance Payable"
              description={
                `₹ ${Math.ceil(
                  tripData?.transporterAmounts?.balancePayable
                )}` || "-"
              }
              marginLeft="0px"
              color={
                tripData?.transporterAmounts?.balancePayable > 0
                  ? "red"
                  : "green"
              }
            />
          )}
          {tripData?.transporterAmounts?.amountPaid !==
            tripData?.transporterAmounts?.finalBidAmount &&
            !tripData?.tripStatus.includes("CANCELLED") && (
              <>
                {/* <StyledButton
                variant="contained"
                color="primary"
                type="submit"
                sx={{}}
                onClick={() => setIsAmountModalOpen(true)}
              >
                Pay Transporter
              </StyledButton> */}
                <StyledButton
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={() => setIsSettlePaymentModalOpen(true)}
                >
                  Settle Transporter Payment
                </StyledButton>
              </>
            )}
        </BoxContainer>
        <StyledTypography
          color="#3F51B5"
          onClick={() => setShowTransaction(!showTransaction)}
        >
          {showTransaction ? "Hide Transaction" : "Show Transaction"}
        </StyledTypography>
      </StyledBox>

      {showTransaction && (
        <Box
          sx={{
            marginTop: "32px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <TransactionTable
            transactionData={transactionData}
            name={tripData?.transporterDetails?.fullName}
          />
        </Box>
      )}
    </Box>
  );
}

const BoxContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  paddingTop: "16px",
  paddingLeft: "32px",
  paddingRight: "32px",
}));

const StyledBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledTypography = styled(Typography)(({ color }) => ({
  display: "inline",
  color: color || "#FF3F3F",
  fontSize: "14px",
  fonWeight: "700",
  marginLeft: "40px",
  cursor: "pointer",
  textDecoration: "underline",
}));

const StyledButton = styled(Button)(() => ({
  padding: "7px 20px",
  fontSize: "0.8rem",
  margin: "7px 0",
}));
