import React, { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import styled from "@emotion/styled";
import HeaderWithUnderline from "../Configurations/Enquiries/HeaderWithUnderline";
import NameDescription from "../Configurations/Enquiries/NameDescription";
import moment from "moment";
import CancelTripModal from "./CancelTripModal";
import { TRIP_STATUS_ARRAY } from "../../../constants/constant";
import EditTrip from "./EditTrip";

const BoxContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  paddingTop: "16px",
  paddingLeft: "32px",
  paddingRight: "32px",
}));

const StyledBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export default function TripDetails({
  enquiryDetails,
  transporterAmounts,
  customerAmounts,
  tripStatus,
  tripId,
  vehicleCategory,
  handleOpenToast,
  getTripById,
  vehicleName,
  customerDetails,
}) {
  const formatDateAndTime = (isoDate) => {
    const date = moment(isoDate).format("DD-MM-YYYY");
    const time = moment(isoDate).format("hh:mm A");
    return `${date} ${time}`;
  };

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleCloseCancelModal = () => {
    setIsCancelModalOpen(false);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const tripSats = TRIP_STATUS_ARRAY.find(
    (status) => status.label === tripStatus
  )?.value;

  return (
    <Box sx={{ padding: "24px", width: "100%" }}>
      <HeaderWithUnderline header="Trip Details" />
      {isCancelModalOpen && (
        <CancelTripModal
          isModalOpen={isCancelModalOpen}
          handleCloseModal={handleCloseCancelModal}
          pickupLocation={enquiryDetails?.pickup?.location?.address}
          dropoffLocation={enquiryDetails?.dropoff?.location?.address}
          distance={enquiryDetails?.distance}
          tripId={tripId}
          vehicleCategory={vehicleCategory}
          handleOpenToast={handleOpenToast}
          getTripById={getTripById}
          vehicleName={vehicleName}
        />
      )}
      <Box>
        <StyledBox>
          <BoxContainer marginLeft="0px" gap="30px">
            <NameDescription
              name="Pickup Location"
              description={
                <>
                  <Box style={{ marginBottom: "8px" }}>
                    Location: {enquiryDetails?.pickup?.location?.address || "-"}
                  </Box>
                  <Divider />
                  <Box style={{ marginTop: "8px" }}>
                    Exact Location:{" "}
                    {enquiryDetails?.pickup?.exactLocation?.address || "-"}
                  </Box>
                </>
              }
              color="#6D6D6D"
              width="300px"
              marginLeft="0px"
            />
            <NameDescription
              name="Date & Time"
              description={
                enquiryDetails?.pickup?.date
                  ? formatDateAndTime(enquiryDetails?.pickup?.date)
                  : "-"
              }
              marginLeft="0px"
              color="#6D6D6D"
            />

            <NameDescription
              name="Drop Off Location"
              description={
                <>
                  <Box style={{ marginBottom: "8px" }}>
                    Location:{" "}
                    {enquiryDetails?.dropoff?.location?.address || "-"}
                  </Box>
                  <Divider />
                  <Box style={{ marginTop: "8px" }}>
                    Exact Location:{" "}
                    {enquiryDetails?.dropoff?.exactLocation?.address || "-"}
                  </Box>
                </>
              }
              marginLeft="0px"
              width="300px"
              color="#6D6D6D"
            />
            <NameDescription
              name="Date & Time"
              description={
                enquiryDetails?.dropoff?.date
                  ? formatDateAndTime(enquiryDetails?.dropoff?.date)
                  : "-"
              }
              marginLeft="0px"
              color="#6D6D6D"
            />
          </BoxContainer>
        </StyledBox>

        <Box
          sx={{
            marginTop: "32px",
            display: "flex",
            width: "100%",
            borderRadius: "8px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <NameDescription
              name="Material Type"
              description={enquiryDetails?.material?.name || "-"}
              color="#6D6D6D"
            />
            <NameDescription
              name="weight"
              description={`${enquiryDetails?.weight} KG` || "-"}
              marginLeft="40px"
              color="#6D6D6D"
            />
            <NameDescription
              name="No of Pkgs"
              description={enquiryDetails?.quantity || "-"}
              marginLeft="40px"
              color="#6D6D6D"
            />

            <NameDescription
              name="Distance"
              description={`${enquiryDetails?.distance} KM` || "-"}
              marginLeft="40px"
              color="#6D6D6D"
            />
            <NameDescription
              name="Transporter Quote"
              description={`₹ ${
                Math.ceil(transporterAmounts?.finalBidAmount) || "-"
              }`}
              marginLeft="40px"
              color="#6D6D6D"
            />
            <NameDescription
              name="LE Margine"
              description={`₹ ${Math.ceil(customerAmounts?.LEMargin) || "-"}`}
              marginLeft="40px"
              color="#6D6D6D"
            />
            <NameDescription
              name="Final Amount"
              description={`₹ ${
                Math.ceil(customerAmounts?.finalAmount) || "-"
              }`}
              marginLeft="40px"
              color="#6D6D6D"
            />
            <NameDescription
              name="Pick up Contact Person"
              description={customerDetails?.fullName || "-"}
              marginLeft="40px"
              color="#6D6D6D"
            />
            <NameDescription
              name="Pick up Contact Number"
              description={customerDetails?.phone || "-"}
              marginLeft="40px"
              color="#6D6D6D"
            />
          </Box>

          {isEditModalOpen && (
            <EditTrip
              enquiryDetails={enquiryDetails}
              customerAmounts={customerAmounts}
              transporterAmounts={transporterAmounts}
              tripId={tripId}
              getTripById={getTripById}
              handleCloseModal={handleCloseEditModal}
            />
          )}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <StyledEditTrip onClick={() => setIsEditModalOpen(true)}>
              Edit Trip
            </StyledEditTrip>
            {tripSats === "Upcoming" && (
              <StyledCancelTrip onClick={() => setIsCancelModalOpen(true)}>
                Cancel Trip
              </StyledCancelTrip>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const StyledCancelTrip = styled(Typography)(() => ({
  marginTop: "32px",
  color: "red",
  fontSize: "14px",
  fontWeight: 400,
  cursor: "pointer",
  textDecoration: "underline",
}));

const StyledEditTrip = styled(Typography)(() => ({
  marginTop: "32px",
  color: "green",
  fontSize: "14px",
  fontWeight: 400,
  cursor: "pointer",
  textDecoration: "underline",
}));
