import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { State, City } from "country-state-city";
import { CONFIRM_BOOKING_DIALOG_STEPS } from "../../constants/constant";
import { getCustomerEnquiryDetailsById } from "../../api/Customer/enquiry";
import { DialogBtn } from "../../component/Common/DialogBtn";
import WhatsAppPickUpDetails from "../../component/Customer/WhatsAppPickUpDetails";
import { useParams } from "react-router-dom";
import WhatsAppOfferDetailsAndPayment from "../../component/Customer/WhatsAppOfferDetailsAndPayment";
import useToast from "../../hooks/useToast";

function WhatsAppAcceptOffer() {
  const [pickUpStateOptions, setPickUpstateOptions] = useState([]);
  const [pickUpCityOptions, setPickUpCityOptions] = useState([]);
  const [dropOffStateOptions, setDropOffStateOptions] = useState([]);
  const [dropOffCityOptions, setDropOffCityOptions] = useState([]);
  const [enquiryDetails, setEnquiryDetails] = useState({});
  const [contactPersonDetails, setContactPersonDetails] = useState({});

  const [step, setStep] = useState(
    CONFIRM_BOOKING_DIALOG_STEPS.CONTACT_PERSON_ADDRESS
  );

  const [pickupAddress, setPickupAddress] = useState({
    addressLine1: "",
    addressLine1Error: "",
    state: "",
    stateError: "",
    city: "",
    cityError: "",
  });

  const [dropoffAddress, setDropoffAddress] = useState({
    addressLine1: "",
    addressLine1Error: "",
    state: "",
    stateError: "",
    city: "",
    cityError: "",
  });

  const parseAddress = (addressString) => {
    const addressParts = addressString.split(",");
    const numParts = addressParts.length;
    const plotAndBuilding = addressParts.slice(0, -3).join(",").trim() || "";
    const city = addressParts[numParts - 3]?.trim() || "";
    const state = addressParts[numParts - 2]?.trim() || "";

    return { plotAndBuilding, city, state };
  };

  const handleChange = (name, value, setAddress) => {
    if (value?.length <= 50) {
      setAddress((prevState) => ({
        ...prevState,
        [name]: value,
        ...(value.trim() !== "" && { [`${name}Error`]: "" }),
      }));
    }
  };

  const handleStateChange = (selectedStateIsoCode, setCityOptions) => {
    const cities = City.getCitiesOfState("IN", selectedStateIsoCode);
    setCityOptions(
      cities.map((city) => ({ value: city.name, label: city.name }))
    );
  };

  const validateAddress = (address) => {
    const errors = {};
    Object.entries(address).forEach(([key, value]) => {
      if (value.trim() === "" && !key.includes("Error")) {
        errors[`${key}Error`] = "This field is required";
      }
    });
    return errors;
  };

  const handleNext = () => {
    if (step === CONFIRM_BOOKING_DIALOG_STEPS.CONTACT_PERSON_ADDRESS) {
      const pickupAddressErrors = validateAddress(pickupAddress);
      const dropoffAddressErrors = validateAddress(dropoffAddress);
      if (
        Object.values(pickupAddressErrors).some((error) => error !== "") ||
        Object.values(dropoffAddressErrors).some((error) => error !== "")
      ) {
        setPickupAddress({ ...pickupAddress, ...pickupAddressErrors });
        setDropoffAddress({ ...dropoffAddress, ...dropoffAddressErrors });
        return;
      }

      setStep(CONFIRM_BOOKING_DIALOG_STEPS.PICKUP_CONTACT_DETAILS);
    }
  };

  const { handleOpenToast, ToastMessage } = useToast();

  useEffect(() => {
    const states = State.getStatesOfCountry("IN");
    setPickUpstateOptions(
      states.map((state) => ({ value: state.isoCode, label: state.name }))
    );
    setDropOffStateOptions(
      states.map((state) => ({ value: state.isoCode, label: state.name }))
    );
  }, []);

  useEffect(() => {
    const states = State.getStatesOfCountry("IN");
    setPickUpstateOptions(
      states.map((state) => ({ value: state.isoCode, label: state.name }))
    );
    setDropOffStateOptions(
      states.map((state) => ({ value: state.isoCode, label: state.name }))
    );

    if (enquiryDetails) {
      if (enquiryDetails.pickup) {
        const { address, city, loc } = enquiryDetails.pickup.location;
        const parsedAddress = parseAddress(address);
        const selectedState = states.find(
          (state) =>
            state.name === parsedAddress.state ||
            state.isoCode === loc?.stateCode
        );

        if (selectedState) {
          handleStateChange(selectedState.isoCode, setPickUpCityOptions);

          setPickupAddress((prevState) => ({
            ...prevState,
            addressLine1: parsedAddress.plotAndBuilding,
            state: selectedState.isoCode,
            city: city || parsedAddress.city,
          }));
        }
      }

      if (enquiryDetails.dropoff) {
        const { address, city, loc } = enquiryDetails.dropoff.location;
        const parsedAddress = parseAddress(address);
        const selectedState = states.find(
          (state) =>
            state.name === parsedAddress.state ||
            state.isoCode === loc?.stateCode
        );

        if (selectedState) {
          handleStateChange(selectedState.isoCode, setDropOffCityOptions);

          setDropoffAddress((prevState) => ({
            ...prevState,
            addressLine1: parsedAddress.plotAndBuilding,
            state: selectedState.isoCode,
            city: city || parsedAddress.city,
          }));
        }
      }
    }
  }, [enquiryDetails]);

  const { customerId, enquiryId } = useParams();

  const getCustomerEnquiryDetails = async (customerId, enquiryId) => {
    try {
      const response = await getCustomerEnquiryDetailsById(
        customerId,
        enquiryId
      );
      if (response.status === 200) {
        setEnquiryDetails(response.data.data.enquiry);
      }
    } catch (error) {
      handleOpenToast("Error fetching enquiry details", "error");
    }
  };

  useEffect(() => {
    getCustomerEnquiryDetails(customerId, enquiryId);
  }, [customerId, enquiryId]);

  if (!enquiryDetails?.enquiryStatus) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          width: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (enquiryDetails?.enquiryStatus !== "OFFER_RECEIVED") {
    return (
      <Box
        sx={{
          p: 4,
          backgroundColor: "#f4f6f8",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "0 auto",
            mb: 4,
            backgroundColor: "#ffffff",
            borderRadius: 2,
            boxShadow: "0px 2px 10px rgba(0,0,0,0.1)",
            padding: "16px",
          }}
        >
          <Typography sx={{ fontSize: 18, fontWeight: 700, color: "#333333" }}>
            Your enquiry status is{" "}
            {enquiryDetails?.enquiryStatus?.split("_").join(" ").toLowerCase()}{" "}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, backgroundColor: "#f4f6f8" }}>
      <ToastMessage />
      <Box sx={{ width: "100%", maxWidth: "1024px", margin: "0 auto", mb: 4 }}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                gap: "0.5rem",
              }}
            >
              <img
                src="/assets/icons/LZ-logo-face.svg"
                alt="logoface"
                style={{ marginBottom: "0.35rem" }}
              />

              <Typography
                sx={{ fontSize: 18, fontWeight: 700, color: "#333333" }}
              >
                Confirm Your Booking Details
              </Typography>
            </Box>
            <Typography
              sx={{ fontSize: 10, fontWeight: 700, color: "#808080", mt: 1 }}
            >
              {step === CONFIRM_BOOKING_DIALOG_STEPS.CONTACT_PERSON_ADDRESS
                ? "This address will be considered as the local pickup and drop-off location for your booking. Please fill in the details carefully!"
                : step === CONFIRM_BOOKING_DIALOG_STEPS.PICKUP_CONTACT_DETAILS
                ? "Please ensure that you enter the contact numbers of the pickup and drop-off persons correctly, as the OTP will be sent to these mobile numbers during the pickup and drop-off processes."
                : "Review your booking information and complete your payment."}
            </Typography>
          </Box>
        </Box>
      </Box>
      {step === CONFIRM_BOOKING_DIALOG_STEPS.CONTACT_PERSON_ADDRESS && (
        <Box>
          <Grid container spacing={3}>
            {/* Pickup Address */}
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  p: 3,
                  backgroundColor: "#ffffff",
                  borderRadius: 2,
                  boxShadow: "0px 2px 10px rgba(0,0,0,0.1)",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 700,
                    color: "#333333",
                    mb: 3,
                  }}
                >
                  Pickup Address Details{" "}
                </Typography>
                <CustomTextInput
                  label="Plot No., Building/Warehouse Name"
                  value={pickupAddress.addressLine1}
                  error={pickupAddress.addressLine1Error}
                  handleInputChange={(e) =>
                    handleChange(
                      "addressLine1",
                      e.target.value,
                      setPickupAddress
                    )
                  }
                  containerStyle={{ marginBottom: 2 }}
                  mandatory
                />
                {/* <CustomTextInput
                  label="Street, Area, Locality"
                  value={pickupAddress.addressLine2}
                  error={pickupAddress.addressLine2Error}
                  handleInputChange={(e) =>
                    handleChange(
                      "addressLine2",
                      e.target.value,
                      setPickupAddress
                    )
                  }
                  containerStyle={{ marginBottom: 2 }}
                /> */}
                <Box mb={2}>
                  <StyledInputLabel>
                    State <span style={{ color: "red" }}> *</span>
                  </StyledInputLabel>
                  <Select
                    fullWidth
                    value={pickupAddress.state}
                    onChange={(e) => {
                      handleChange("state", e.target.value, setPickupAddress);
                      handleStateChange(e.target.value, setPickUpCityOptions);
                    }}
                    sx={{
                      borderRadius: 1,
                      boxShadow: "0px 1px 4px rgba(0,0,0,0.05)",
                    }}
                  >
                    {pickUpStateOptions.map((state) => (
                      <MenuItem key={state.value} value={state.value}>
                        {state.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <small style={{ color: "red", fontSize: 12 }}>
                    {pickupAddress.stateError}
                  </small>
                </Box>
                <Box mb={3}>
                  <StyledInputLabel>
                    City <span style={{ color: "red" }}> *</span>
                  </StyledInputLabel>
                  <Select
                    fullWidth
                    disabled={!pickupAddress.state}
                    value={pickupAddress.city}
                    onChange={(e) =>
                      handleChange("city", e.target.value, setPickupAddress)
                    }
                    sx={{
                      borderRadius: 1,
                      boxShadow: "0px 1px 4px rgba(0,0,0,0.05)",
                    }}
                  >
                    {pickUpCityOptions.map((city) => (
                      <MenuItem key={city.value} value={city.value}>
                        {city.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <small style={{ color: "red", fontSize: 12 }}>
                    {pickupAddress.cityError}
                  </small>
                </Box>
              </Box>
            </Grid>

            {/* Dropoff Address */}
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  p: 3,
                  backgroundColor: "#ffffff",
                  borderRadius: 2,
                  boxShadow: "0px 2px 10px rgba(0,0,0,0.1)",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 700,
                    color: "#333333",
                    mb: 3,
                  }}
                >
                  Dropoff Address Details{" "}
                </Typography>
                <CustomTextInput
                  label="Plot No., Building/Warehouse Name"
                  value={dropoffAddress.addressLine1}
                  error={dropoffAddress.addressLine1Error}
                  handleInputChange={(e) =>
                    handleChange(
                      "addressLine1",
                      e.target.value,
                      setDropoffAddress
                    )
                  }
                  containerStyle={{ marginBottom: 2 }}
                  mandatory
                />
                {/* <CustomTextInput
                  label="Street, Area, Locality"
                  value={dropoffAddress.addressLine2}
                  error={dropoffAddress.addressLine2Error}
                  handleInputChange={(e) =>
                    handleChange(
                      "addressLine2",
                      e.target.value,
                      setDropoffAddress
                    )
                  }
                  containerStyle={{ marginBottom: 2 }}
                /> */}
                <Box mb={2}>
                  <StyledInputLabel>
                    State
                    <span style={{ color: "red" }}> *</span>
                  </StyledInputLabel>
                  <Select
                    fullWidth
                    value={dropoffAddress.state}
                    onChange={(e) => {
                      handleChange("state", e.target.value, setDropoffAddress);
                      handleStateChange(e.target.value, setDropOffCityOptions);
                    }}
                    sx={{
                      borderRadius: 1,
                      boxShadow: "0px 1px 4px rgba(0,0,0,0.05)",
                    }}
                  >
                    {dropOffStateOptions.map((state) => (
                      <MenuItem key={state.value} value={state.value}>
                        {state.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <small style={{ color: "red", fontSize: 12 }}>
                    {dropoffAddress.stateError}
                  </small>
                </Box>
                <Box mb={3}>
                  <StyledInputLabel>
                    City
                    <span style={{ color: "red" }}> *</span>
                  </StyledInputLabel>
                  <Select
                    fullWidth
                    disabled={!dropoffAddress.state}
                    value={dropoffAddress.city}
                    onChange={(e) =>
                      handleChange("city", e.target.value, setDropoffAddress)
                    }
                    sx={{
                      borderRadius: 1,
                      boxShadow: "0px 1px 4px rgba(0,0,0,0.05)",
                    }}
                  >
                    {dropOffCityOptions.map((city) => (
                      <MenuItem key={city.value} value={city.value}>
                        {city.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <small style={{ color: "red", fontSize: 12 }}>
                    {dropoffAddress.cityError}
                  </small>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <DialogBtn
              title="Next"
              onClick={handleNext}
              buttonStyle={{
                color: "#FFFFFF",
                background: "#0C7F44",
                "&:hover": {
                  backgroundColor: "#0A6740",
                  opacity: 0.85,
                },
                borderRadius: 1,
                padding: "12px 30px",
                fontSize: "1rem",
                boxShadow: "0px 2px 8px rgba(0,0,0,0.15)",
              }}
            />
          </Box>
        </Box>
      )}
      {step === CONFIRM_BOOKING_DIALOG_STEPS.PICKUP_CONTACT_DETAILS && (
        <WhatsAppPickUpDetails
          setStep={setStep}
          setContactPersonDetails={setContactPersonDetails}
          contactPersonDetails={contactPersonDetails}
          enquiryDetails={enquiryDetails}
        ></WhatsAppPickUpDetails>
      )}
      {step === CONFIRM_BOOKING_DIALOG_STEPS.CONFIRM_AND_PAY && (
        <WhatsAppOfferDetailsAndPayment
          enquiryDetails={enquiryDetails}
          pickupAddress={pickupAddress}
          dropoffAddress={dropoffAddress}
          handleOpenToast={handleOpenToast}
          setStep={setStep}
          contactPersonDetails={contactPersonDetails}
        ></WhatsAppOfferDetailsAndPayment>
      )}
    </Box>
  );
}

export default WhatsAppAcceptOffer;

const CustomTextInput = ({
  label,
  handleInputChange,
  value,
  error,
  placeholder,
  helperText,
  containerStyle,
  mandatory,
}) => {
  return (
    <Box sx={{ width: "100%", ...containerStyle }}>
      <StyledInputLabel>
        {label}

        {mandatory && <span style={{ color: "red" }}> *</span>}
      </StyledInputLabel>
      <TextField
        variant="outlined"
        value={value}
        placeholder={placeholder}
        fullWidth
        InputProps={{
          style: {
            height: "50px",
            marginTop: "4px",
            width: "100%",
          },
        }}
        onChange={handleInputChange}
        helperText={helperText}
      />
      <small style={{ color: "red", fontSize: 12 }}>{error}</small>
    </Box>
  );
};

const StyledInputLabel = ({ children }) => {
  return (
    <InputLabel
      htmlFor="name-input"
      sx={{
        color: "#333",
        fontFamily: "Helvetica",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        textTransform: "capitalize",
      }}
    >
      {children}
    </InputLabel>
  );
};
