import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { useState } from "react";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import { rebookEnquiry } from "../../../api/Customer/enquiry";
import StyledSelectInput from "../../../component/Common/StyledSelectInput";
import useToast from "../../../hooks/useToast";
import { PlacesAutocomplete } from "../../../component/Common/PlacesAutoComplete";
import EnquiryEstimatePage from "../../Enquiry/EnquiryEstimatePage";
import { useMapsService } from "../../../hooks/useMapsService";
const labelStyle = { fontWeight: 700, fontSize: "1.125rem", color: "#333" };
const RebookForm = ({
  setSwitchComp,
  formData,
  setFormData,
  vehicleList,
  vehicleModels,
  materialList,
  timeSlots,
  unit,
  setUnit,
}) => {
  const { handleOpenToast, ToastMessage } = useToast();
  const [isTruckTypeEnabled, setIsTruckTypeEnabled] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(true);
  const [isVehicleCategoryOpen, setIsVehicleCategoryOpen] = useState(false);
  const [startTime, setStartTime] = useState(
    formData?.slot ? formData?.slot.split("-")[0] : null
  );

  const [enquiryId, setEnquiryId] = useState("");

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    if (name === "weight") {
      if (!isTruckTypeEnabled) {
        setIsTruckTypeEnabled(true);
      }
      if (isNaN(value)) return;
    }
    if (name === "slot") {
      const selectedSlot = timeSlots.filter((slot) => slot.value === value);
      setStartTime(selectedSlot[0].startTime);
    }
    if (name === "material") {
      const selectedMaterial = materialList.filter(
        (material) => material.value === value
      );

      setFormData((prevValues) => ({
        ...prevValues,
        material: {
          category: selectedMaterial[0]?.category,
          name: selectedMaterial[0]?.value,
          categoryId: selectedMaterial[0]?.id,
        },
      }));
      return;
    }
    if (name === "vehicleCategory") {
      if (value === "No preference") {
        setFormData((prevValues) => ({
          ...prevValues,
          vehicleCategory: 1,
        }));
      } else {
        setFormData((prevValues) => ({
          ...prevValues,
          vehicleCategory: value,
          vehicleCategoryId: vehicleList.filter(
            (vehicle) => vehicle.value === value
          )[0]?.id,
        }));
      }
      return;
    }

    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const [pickUpPlaceId, setPickUpPlaceId] = useState("");
  const [dropOffPlaceId, setDropOffPlaceId] = useState("");
  const { getLatLng, calculateDistance } = useMapsService();
  const getLocation = async (placeId) => {
    try {
      const res = await getLatLng(placeId);
      return res;
    } catch (err) {
      if (err.message === `no cityError ${pickUpPlaceId}`) {
        handleOpenToast("Select a valid pick up address", "error");
      }
      if (err.message === `no cityError ${dropOffPlaceId}`) {
        handleOpenToast("Select a valid drop off address", "error");
      } else {
        handleOpenToast("Cannot perform this action. Try again", "error");
      }
      return err;
    }
  };

  const handleForm = async (e) => {
    e.preventDefault();
    if (!formData?.pickupLocation?.address) {
      handleOpenToast("Select valid pickup location", "error");
      return;
    }
    if (!formData?.dropOffLocation?.address) {
      handleOpenToast("Select valid drop off location", "error");
      return;
    }
    if (!formData?.date) {
      handleOpenToast("Select a valid date", "error");
      return;
    }
    if (!formData?.slot) {
      handleOpenToast("Select a valid slot", "error");
      return;
    }

    if (!formData?.weight) {
      handleOpenToast("Enter valid weight", "error");
      return;
    }
    if (!formData?.material) {
      handleOpenToast("Select a valid material type", "error");
      return;
    }
    if (!formData?.vehicleCategory) {
      handleOpenToast("Select a valid vehicle", "error");
      return;
    }
    if (!formData?.name) {
      handleOpenToast("Enter a valid name", "error");
      return;
    }

    let datePart = formData?.date;
    let timePart = formData?.slot.split("-")[0];
    let time24 = timePart.includes("AM")
      ? timePart.substring(0, 5)
      : (parseInt(timePart.substring(0, 2)) % 12) +
        12 +
        timePart.substring(2, 5);
    let dateTimeString = `${datePart}T${time24}+05:30`;

    const pickUpData = pickUpPlaceId
      ? await getLocation(pickUpPlaceId)
      : formData?.pickupLocation;

    const dropOffData = dropOffPlaceId
      ? await getLocation(dropOffPlaceId)
      : formData?.dropOffLocation;

    const calculateNewDistance = async (pickup, dropoff) => {
      try {
        const res = await calculateDistance(pickup, dropoff);
        setFormData((p) => ({ ...p, distance: res, updatedDistance: true }));
        return res;
      } catch (err) {
        handleOpenToast("Cannot calculate distance. Try again", "error");
        return err;
      }
    };

    const distance =
      pickUpPlaceId || dropOffPlaceId
        ? await calculateNewDistance(
            formData?.pickupLocation?.address,
            formData?.dropOffLocation?.address
          )
        : formData?.distance;

    let requestBody = {
      material: formData?.material,
      weight: unit === "KG" ? formData?.weight : 1000 * formData?.weight,
      pickupDate: new Date(dateTimeString).toISOString(),
      vehicleCategory: 1,
      pickupSlot: formData?.slot,
      quantity: formData?.quantity,
      pickup: {
        location: {
          address: pickUpData?.address,
          latitude: pickUpData?.latitude,
          longitude: pickUpData?.longitude,
          city: pickUpData?.city,
        },
      },
      dropoff: {
        location: {
          address: dropOffData?.address,
          latitude: dropOffData?.latitude,
          longitude: dropOffData?.longitude,
          city: dropOffData?.city,
        },
      },
      distance: distance,
      customerId: formData?.customerId,
      customerName: formData?.name,
    };
    if (formData?.vehicleCategory !== 1) {
      requestBody = {
        ...requestBody,
        vehicleCategory: formData?.vehicleCategory?.split(" ")[0],
        vehicleCategoryId: vehicleList.filter(
          (vehicle) => vehicle.value === formData?.vehicleCategory
        )[0]?.id,
      };
    }

    try {
      const res = await rebookEnquiry(requestBody);
      setEnquiryId(res?.data?.data?._id);
    } catch (err) {
      handleOpenToast("Something went wrong.", "error");
    }
  };

  if (enquiryId) {
    return <EnquiryEstimatePage enquiryId={enquiryId} />;
  }

  return (
    <>
      <ToastMessage />
      <WarningBoxModal
        vehicleCategory={formData?.vehicleCategory}
        isWarningModalOpen={isWarningModalOpen}
        setIsWarningModalOpen={setIsWarningModalOpen}
        setIsVehicleCategoryOpen={setIsVehicleCategoryOpen}
      />
      <form onSubmit={handleForm}>
        <Grid container spacing={2}>
          <Grid item xs={6} width="100%">
            <InputLabel shrink sx={labelStyle}>
              Pickup Location
            </InputLabel>
            <PlacesAutocomplete
              value={formData?.pickupLocation?.address}
              placeholderText="Enter pick up location"
              onPlaceSelect={(place, placeId) => {
                setPickUpPlaceId(placeId);
                setFormData((p) => ({
                  ...p,
                  pickupLocation: {
                    address: place,
                  },
                }));
              }}
            />
          </Grid>

          <Grid item xs={6} width="100%">
            <InputLabel shrink sx={labelStyle}>
              Drop-off Location
            </InputLabel>
            <PlacesAutocomplete
              value={formData?.dropOffLocation?.address}
              placeholderText="Enter drop off location"
              onPlaceSelect={(place, placeId) => {
                setDropOffPlaceId(placeId);
                setFormData((p) => ({
                  ...p,
                  dropOffLocation: {
                    address: place,
                  },
                }));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledSelectInput
              label="Material Name"
              name="material"
              value={formData?.material?.name}
              id={"material"}
              onChange={handleFormDataChange}
              items={materialList || []}
            />
          </Grid>

          <Grid item xs={6}>
            <Stack>
              <InputLabel shrink sx={labelStyle}>
                Weight
              </InputLabel>
              <Stack direction="row" spacing={2}>
                <TextField
                  name="weight"
                  placeholder="Enter Weight"
                  autoComplete="off"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "2.75rem",
                      paddingRight: 0,
                    },
                  }}
                  fullWidth
                  value={formData?.weight}
                  onChange={handleFormDataChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Select
                          value={unit}
                          onChange={(e) => {
                            setUnit(e.target.value);
                          }}
                          sx={{
                            width: "100px",
                            borderColor: "red",
                            "& .MuiOutlinedInput-notchedOutline , &.Mui-focused .MuiOutlinedInput-notchedOutline ,& .MuiOutlinedInput-root":
                              {
                                borderColor: "#EAEAEA !important",
                              },
                          }}
                        >
                          <MenuItem value="KG">KG</MenuItem>
                          <MenuItem value="TONS">TONS</MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack>
              <InputLabel shrink sx={labelStyle}>
                No of Pkgs
              </InputLabel>
              <TextField
                name="quantity"
                value={formData?.quantity}
                id={"quantity"}
                onChange={handleFormDataChange}
                fullWidth
                placeholder="Enter No of Pkgs."
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "2.75rem",
                    paddingRight: 0,
                  },
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <VehicleCategorySelect
              value={formData?.vehicleCategory}
              onChange={handleFormDataChange}
              items={vehicleList}
              isVehicleCategoryOpen={isVehicleCategoryOpen}
              setFormData={setFormData}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <InputLabel shrink sx={labelStyle}>
                Name
              </InputLabel>
              <Stack direction="row" spacing={2}>
                <TextField
                  name="name"
                  autoComplete="off"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "2.75rem",
                      paddingRight: 0,
                    },
                  }}
                  fullWidth
                  value={formData?.name}
                  onChange={handleFormDataChange}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <InputLabel shrink sx={labelStyle}>
                Pickup Date
              </InputLabel>
              <DateInput formData={formData} setFormData={setFormData} />
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <StyledSelectInput
              label="Pickup Time Slot"
              name="slot"
              value={formData?.slot}
              onChange={handleFormDataChange}
              items={timeSlots}
              disabled={!formData?.date}
            />
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="center" mt={6}>
          <Button color="primary" variant="contained" type="submit">
            Get Estimation
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default RebookForm;

const VehicleCategorySelect = ({
  items,
  onChange,
  value = "",
  isVehicleCategoryOpen,
  setFormData,
  error,
  helperText,
}) => {
  const [open, setOpen] = useState(isVehicleCategoryOpen);
  const handleSelectClick = () => {
    setOpen(!open);
  };

  const handleSelectClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <InputLabel shrink sx={labelStyle}>
        Vehicle Category
      </InputLabel>
      <Select
        open={open}
        onClose={handleSelectClose}
        onOpen={handleSelectClick}
        onClick={handleSelectClick}
        value={value === 1 ? "No preference" : value}
        onChange={onChange}
        name="vehicleCategory"
        id="open"
        fullWidth
        sx={{
          color: value ? "#333" : "#ADADAD",
          height: "2.75rem",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ADADAD",
          },
        }}
      >
        <MenuItem disabled value="" sx={{ display: "none" }}>
          Select
        </MenuItem>
        {items?.length > 0 ? (
          items?.map((e) => (
            <MenuItem key={e.id} value={e.value}>
              {e.value}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="No Preference">No Preference</MenuItem>
        )}
      </Select>
    </div>
  );
};

const WarningBoxModal = ({
  vehicleCategory,
  isWarningModalOpen,
  setIsWarningModalOpen,
  setIsVehicleCategoryOpen,
}) => {
  return (
    <Dialog
      open={vehicleCategory === "No preference (any)" && isWarningModalOpen}
    >
      <Box width={500} height={350} textAlign="center" p="1rem 2rem">
        <WarningAmberRoundedIcon
          sx={{ color: "#F28929", fontSize: "3.75rem" }}
        />
        <DialogTitle>Truck Selection Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            For an accurate fare estimate, select a truck type. If not, the fare
            will be shown once offers are received.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIsWarningModalOpen(false);
              setIsVehicleCategoryOpen(true);
              document.getElementById("open").click();
            }}
          >
            Select Preferred Vehicle
          </Button>
          <Button
            onClick={() => setIsWarningModalOpen(false)}
            sx={{ textTransform: "none" }}
          >
            Proceed without vehicle
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

const DateInput = ({ formData, setFormData }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        slots={{
          openPickerIcon: EditCalendarRoundedIcon,
        }}
        value={moment(formData?.date)}
        minDate={moment(new Date())}
        defaultValue={new Date()}
        sx={{
          "& .MuiOutlinedInput-root": {
            height: "2.75rem",
            "&.Mui-error": {
              "& fieldset": {
                borderColor: "#c6c6c6",
              },
            },
          },
        }}
        name="date"
        format="DD/MM/YYYY"
        onChange={(newValue) => {
          const dateStr = newValue.format("YYYY-MM-DD");
          setFormData((p) => ({ ...p, date: dateStr }));
        }}
      />
    </LocalizationProvider>
  );
};
