import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Modal,
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  tableCellClasses,
  Stack,
  Button,
  FormControlLabel,
  Checkbox,
  Popover,
} from "@mui/material";
import AddEditTruckModal from "../../../../component/Transporter/Resources/Trucks/AddEditTruckModal";
import TruckChangeConfirmationModal from "../../../../component/Transporter/Resources/Trucks/TruckChangeConfirmationModal";
import DeleteTruckModal from "../../../../component/Transporter/Resources/Trucks/DeleteTruckModal";
import {
  getAllTruckData,
  getUpcomingTripsOfVehicle,
} from "../../../../api/Transporter/truck";
import styled from "@emotion/styled";
import TruckCardDetails from "../../../../component/Transporter/Resources/Trucks/TruckCardDetails";
import TripDetails from "../../../../component/Transporter/Resources/Trucks/TripDetails";
import { useSelector } from "react-redux";
import SearchBox from "../../../../component/Common/SearchBox";
import useToast from "../../../../hooks/useToast";
import { LOADING_STATUS } from "../../../../constants/constant";
import LoadingTable from "../../../../component/Common/LoadingTable";
import ErrorScreen from "../../../../component/Common/ErrorScreen";
import { getTruckTrips } from "../../../../api/Transporter/trip";

const Truck = () => {
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    border: "6px solid #FFF",
    background: "#F6F6F6",
    "&:hover": {
      background: "#E9F2F4",
    },
    textDecoration: "none",
    cursor: "pointer",
    td: {
      paddingInline: "1rem",
      paddingBlock: ".35rem",
      fontWeight: 700,
      fontSize: "12px",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FFFFFF",
      color: "#333333",
      borderRight: "1px solid #c6c6c6",
      fontWeight: 700,
      width: "25%",
    },
  }));

  const transporterTruckStatusCountContainer = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "6px 10px",
    border: "1px solid #C6C6C6",
    background: "#EAEAEA",
    gap: "1rem",
    borderRadius: 1,
  };

  const headerText = {
    color: "#333",
    fontWeight: 700,
    fontSize: "18px",
  };

  // Varibale to show toast messages.
  const { toastData, handleCloseToast, handleOpenToast, ToastMessage } =
    useToast();

  // Varibale to show toast messages.
  const [isVehicleDataFetched, setIsVehicleDataFetched] = useState(
    LOADING_STATUS.INITIAL
  );

  // Accessing transporter id from application state.
  const transporterProfile = useSelector((state) => state.transporterProfile);

  // State for controlling the visibility of add/edit truck modal
  const [isAddEditTruckTypeOpen, setIsAddEditTruckTypeOpen] = useState(false);

  // State for controlling the visibility of delete truck modal
  const [isDeleteTruckModalOpen, setIsDeleteTruckModalOpen] = useState(false);

  // State for managing truck change confirmation modal visibility
  const [
    isTruckChangeConfirmationModalOpen,
    setIsTruckChangeConfirmationModalOpen,
  ] = useState(false);

  // State for storing vehicle data (list of trucks)
  const [vehicleData, setVehicleData] = useState([]);

  // State for managing the currently selected vehicle
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  // State for checking if the transporter has any upcoming trips
  const [isTransporterHasUpcomingTrips, setIsTransporterHasUpcomingTrips] =
    useState(false);

  // State for pagination data
  const [paginationData, setPaginationData] = useState({});

  // State for managing the current page number in pagination
  const [page, setPage] = useState(0);
  const [counts, setCounts] = useState({});

  // State to keep track of kyc status of transporter.
  const [transporterKycStatus, setTransporterKycStatus] = useState();

  const [mode, setMode] = useState("edit");
  const [activeRow, setActiveRow] = useState(0);

  const [filterValue, setFilterValue] = useState([]);

  // Function to handle a change in page number in pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Function to close the add/edit truck modal
  const closeAddEditTruckModal = () => {
    setIsAddEditTruckTypeOpen(false);
    setMode("edit");
  };

  // Function to open the add truck type modal
  const openAddTruckTypeModal = () => {
    if (transporterKycStatus === "KYC_PENDING") {
      handleOpenToast("Please wait for kyc approval from admin.", "warning");
      return;
    } else if (transporterKycStatus === "KYC_INCOMPLETE") {
      handleOpenToast("Please upload the kyc documents.", "error");
      return;
    } else if (transporterKycStatus === "KYC_REJECTED") {
      handleOpenToast(
        "Your KYC documents were rejected. Please reupload KYC documents.",
        "error"
      );
      return;
    } else {
      setMode("add");
      setIsAddEditTruckTypeOpen(true);
      setSelectedVehicle(null);
    }
  };

  // Function to open the edit truck type modal
  const openEditTruckTypeModal = () => {
    setIsAddEditTruckTypeOpen(true);
    const vehicleSelected = vehicleData[activeRow];
    setSelectedVehicle(vehicleSelected);
  };

  // Function to close the delete truck modal
  const closeDeleteTruckModal = () => {
    setIsDeleteTruckModalOpen(false);
  };

  // Function to open the delete truck modal
  const openDeleteTruckModal = () => {
    setIsDeleteTruckModalOpen(true);
  };

  // Function to close the truck change confirmation modal
  const closeTruckChangeConfirmationModal = () => {
    setIsTruckChangeConfirmationModalOpen(false);
  };

  // Function to open the truck change confirmation modal
  const openTruckChangeConfirmationModal = () => {
    setIsTruckChangeConfirmationModalOpen(true);
  };

  // State to store the trips
  const [trips, setTrips] = useState([]);

  // State to store the fetching status
  const [isTripListFetching, setIsTripListFetching] = useState(false);

  // Accessing transporter id from application state.
  const transporterId = useSelector((state) => state.transporterId.id);

  // State to store the input value for vehicle list search
  const [inputValue, setInputValue] = useState("");

  // State to store the debounced input value for vehicle list search
  const [debouncedInputValue, setDebouncedInputValue] = useState(inputValue);

  // Fetching truck data using API call with provided parameters
  const fetchAllTrucksList = async () => {
    setIsVehicleDataFetched(LOADING_STATUS.LOADING);
    const filterString = filterValue.join(",");
    const response = await getAllTruckData(
      transporterId,
      page + 1,
      15,
      inputValue.toUpperCase(),
      filterString
    );
    if (response.status === 200) {
      setTransporterKycStatus(response?.data?.data?.kycStatus);
      const vehicleData = response?.data?.data?.vehicles;
      setVehicleData(vehicleData);
      if (vehicleData.length) {
        setSelectedVehicle(vehicleData[0]);
        setActiveRow(0);
      }
      setIsVehicleDataFetched(LOADING_STATUS.LOADED);
      setPaginationData(response.data.data.pagination);
      setCounts(response.data.data.counts);
    } else {
      setIsVehicleDataFetched(LOADING_STATUS.FAILED);
    }
  };

  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = useState(null);

  // Handle opening the filter popover
  const handleFilterPopoverOpen = (event) => {
    setFilterPopoverAnchorEl(event.currentTarget);
  };

  // Handle closing the filter popover
  const handleFilterPopoverClose = () => {
    setFilterPopoverAnchorEl(null);
  };

  // Check if the filter popover is open
  const isFilterPopoverOpen = Boolean(filterPopoverAnchorEl);

  // Function to update filter value
  const handleFilterValueChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setFilterValue([...filterValue, value]);
    } else {
      setFilterValue(filterValue.filter((item) => item !== value));
    }
  };

  // function to fetch trips
  const fetchTrips = async () => {
    try {
      setIsTripListFetching(true);
      const response = await getTruckTrips(
        transporterId,
        selectedVehicle?.transporterVehicleId
      );
      if (response.status === 200) {
        setTrips(response.data.data.trips);
      }
      setIsTripListFetching(false);
    } catch (error) {
      setIsTripListFetching(false);
      handleOpenToast("Error while fetching trips", "error");
    }
  };

  // function to update truck status in state
  const handleTruckStatusChange = (vehicleId, newAvailability) => {
    setVehicleData((currentVehicleData) =>
      currentVehicleData.map((vehicle) =>
        vehicle.transporterVehicleId === vehicleId
          ? {
              ...vehicle,
              vehicleStatus: newAvailability ? "AVAILABLE" : "UNAVAILABLE",
            }
          : vehicle
      )
    );
  };

  const getUpcomingTripsOfTruck = async () => {
    try {
      const response = await getUpcomingTripsOfVehicle(
        transporterId,
        selectedVehicle?.transporterVehicleId
      );
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          setIsTransporterHasUpcomingTrips(true);
        } else {
          setIsTransporterHasUpcomingTrips(false);
        }
      }
    } catch (error) {
      handleOpenToast("Error while fetching trips", "error");
    }
  };

  // Function to handle the selection of a vehicle based on its index in the vehicle list
  const handleSelectedIndex = (index) => {
    localStorage.setItem("truckActiveRow", index);
    setActiveRow(index);
    setSelectedVehicle(vehicleData[index]);
  };

  // Function to update the input value based on changes in the search input
  const handleVehicleListSearch = (value) => {
    if (page !== 0 && value !== "") {
      setPage(0);
    }
    const formattedValue = value.replace(/\s/g, "").toUpperCase();
    setInputValue(formattedValue);
  };

  // Debounce search value for 500ms
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [inputValue]);

  // Effect to trigger fetching the truck list when page or input value changes
  useEffect(() => {
    if (transporterId) {
      fetchAllTrucksList();
    }
  }, [page, transporterId, filterValue, debouncedInputValue]);

  useEffect(() => {
    if (selectedVehicle) {
      fetchTrips();
    }
  }, [selectedVehicle]);

  useEffect(() => {
    getUpcomingTripsOfTruck();
  }, [selectedVehicle]);

  function renderViews() {
    switch (isVehicleDataFetched) {
      case LOADING_STATUS.LOADING:
        return <LoadingTable columns={5} />;
      case LOADING_STATUS.LOADED:
        if (vehicleData.length) {
          return vehicleData.map((vehicle, index) => (
            <StyledTableRow
              onClick={() => handleSelectedIndex(index)}
              key={index}
              style={{
                textDecoration: "none",
                cursor: "pointer",
                background: activeRow === index ? "#E9F2F4" : "",
              }}
            >
              <TableCell scope="row" sx={{ color: "#6D6D6D" }}>
                {vehicle?.vehicleNumber}
              </TableCell>
              <TableCell sx={{ color: "#6D6D6D" }}>
                {vehicle?.vehicleCategory} Wheeler
              </TableCell>
              <TableCell sx={{ color: "#6D6D6D" }}>
                {vehicle?.vehicleModelName}
              </TableCell>
              <TableCell sx={{ color: "#6D6D6D" }}>
                {vehicle.driverName ? `${vehicle.driverName}` : "None"}
              </TableCell>
              <TableCell sx={{ color: "#6D6D6D" }}>
                {vehicle.vehicleStatus}
              </TableCell>
            </StyledTableRow>
          ));
        } else {
          return (
            <TableRow sx={{ background: "#EAEAEA" }}>
              <TableCell
                colSpan={5}
                align="center"
                sx={{ fontWeight: 600, color: "#333" }}
              >
                No Results Found.
              </TableCell>
            </TableRow>
          );
        }
      case LOADING_STATUS.FAILED:
        return (
          <TableCell colSpan={5}>
            <ErrorScreen onRetry={fetchAllTrucksList} />
          </TableCell>
        );
      default:
        return null;
    }
  }

  return (
    <Box>
      <ToastMessage
        open={toastData.open}
        message={toastData.message}
        severity={toastData.severity}
        handleClose={handleCloseToast}
      />
      {isAddEditTruckTypeOpen && (
        <AddEditTruckModal
          mode={mode}
          open={isAddEditTruckTypeOpen}
          onClose={closeAddEditTruckModal}
          closeAddEditTruckModal={closeAddEditTruckModal}
          selectedVehicle={selectedVehicle}
          handleOpenToast={handleOpenToast}
          fetchAllTrucksList={fetchAllTrucksList}
          transporterId={transporterId}
        />
      )}
      <Modal
        open={isTruckChangeConfirmationModalOpen}
        onClose={closeTruckChangeConfirmationModal}
      >
        <TruckChangeConfirmationModal
          closeTruckChangeConfirmationModal={closeTruckChangeConfirmationModal}
        />
      </Modal>
      <Modal open={isDeleteTruckModalOpen} onClose={closeDeleteTruckModal}>
        <DeleteTruckModal
          closeDeleteTruckModal={closeDeleteTruckModal}
          selectedVehicle={selectedVehicle || vehicleData[0]}
          handleOpenToast={handleOpenToast}
          fetchAllTrucksList={fetchAllTrucksList}
          transporterId={transporterId}
          isTransporterHasUpcomingTrips={isTransporterHasUpcomingTrips}
        />
      </Modal>
      <Box
        sx={{
          height: "65px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "0 16px",
          borderBottom: "1px solid #ADADAD",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
          <Box sx={transporterTruckStatusCountContainer}>
            <Typography sx={headerText}>Total</Typography>
            <Typography sx={headerText}>{counts.total || 0}</Typography>
          </Box>
          <Box sx={transporterTruckStatusCountContainer}>
            <Typography sx={headerText}>Available</Typography>
            <Typography sx={headerText}>{counts.available || 0}</Typography>
          </Box>
          <Box sx={transporterTruckStatusCountContainer}>
            <Typography sx={headerText}>In Transit</Typography>
            <Typography sx={headerText}>{counts.inTransit || 0}</Typography>
          </Box>
          <Box sx={transporterTruckStatusCountContainer}>
            <Typography sx={headerText}>Unavailable</Typography>
            <Typography sx={headerText}>{counts.unavailable || 0}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "167px",
            height: "40px",
            borderRadius: "3px",
            border: "1px solid #333",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            gap: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={openAddTruckTypeModal}
        >
          <img
            src="/assets/icons/addBox.svg"
            alt="cross"
            style={{ width: "24px", height: "24px" }}
          />
          <Typography sx={headerText}> Add Truck</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          margin: "16px 8px",
          gap: "10px",
          height: "calc(100vh - 12rem)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "65%",
          }}
        >
          <SearchBox handleSearchBoxChange={handleVehicleListSearch} />
          <TableContainer
            component={Paper}
            sx={{ width: "100%", height: "100%" }}
          >
            <Table size="small" aria-label="Truck data">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Vehicle Number</StyledTableCell>
                  <StyledTableCell>Vehicle Category</StyledTableCell>
                  <StyledTableCell>Vehicle Name</StyledTableCell>
                  <StyledTableCell>Driver</StyledTableCell>
                  <TableCell>
                    <StatusBox>
                      Status
                      <img
                        src="/assets/icons/expand_less.svg"
                        alt="expand less transporter status"
                        style={{ cursor: "pointer" }}
                        onClick={handleFilterPopoverOpen}
                      />
                    </StatusBox>
                    <Popover
                      open={isFilterPopoverOpen}
                      anchorEl={filterPopoverAnchorEl}
                      onClose={handleFilterPopoverClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <PopOverBox>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filterValue.includes("AVAILABLE")}
                              onChange={handleFilterValueChange}
                              value="AVAILABLE"
                            />
                          }
                          label="Available"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filterValue.includes("UNAVAILABLE")}
                              onChange={handleFilterValueChange}
                              value="UNAVAILABLE"
                            />
                          }
                          label="Unavailable"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filterValue.includes("IN_TRANSIT")}
                              onChange={handleFilterValueChange}
                              value="IN_TRANSIT"
                            />
                          }
                          label="In Transit"
                        />
                        {filterValue.length > 0 && (
                          <Button
                            onClick={() => {
                              setFilterValue([]);
                            }}
                          >
                            Clear All
                          </Button>
                        )}
                      </PopOverBox>
                    </Popover>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderViews()}</TableBody>
            </Table>
          </TableContainer>
          <div style={{ padding: "10px" }}>
            {vehicleData.length ? (
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={paginationData.totalItems}
                rowsPerPage={paginationData.pageSize}
                page={page}
                onPageChange={handleChangePage}
                labelDisplayedRows={({ count }) => (
                  <>
                    Page{" "}
                    <span style={{ color: "green", fontWeight: 700 }}>
                      {page + 1}
                    </span>{" "}
                    of {Math.ceil(count / paginationData.pageSize)}
                  </>
                )}
              />
            ) : null}
          </div>
        </Box>
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              border: "1px solid #ADADAD",
            }}
          >
            <Box
              sx={{
                height: "100%",
              }}
            >
              {vehicleData && vehicleData.length ? (
                <TruckCardDetails
                  openEditTruckTypeModal={openEditTruckTypeModal}
                  selectedVehicle={selectedVehicle || vehicleData[0]}
                  openDeleteTruckModal={openDeleteTruckModal}
                  transporterId={transporterId}
                  fetchAllTrucksList={fetchAllTrucksList}
                  vehicleStatus={selectedVehicle?.vehicleStatus}
                  handleOpenToast={handleOpenToast}
                  handleTruckStatusChange={handleTruckStatusChange}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      maxWidth: "80%",
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: 700,
                    }}
                  >
                    No truck added yet. Please add truck to see details.
                  </Typography>
                </Box>
              )}
            </Box>
            <TripDetails
              trips={trips}
              isTripListFetching={isTripListFetching}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Truck;

const PopOverBox = styled(Box)({
  padding: "10px",
  display: "flex",
  flexDirection: "column",
});

const StatusBox = styled(Box)({
  fontSize: "14px",
  fontWeight: 700,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
});
