import React from "react";
import {
  Box,
  Typography,
  Button,
  Slider,
  InputAdornment,
  OutlinedInput,
  Skeleton,
} from "@mui/material";
import styled from "@emotion/styled";
import { ENQUIRY_STATUS } from "../../../constants/constant";

const QuoteInput = styled(OutlinedInput)(({ disabled }) => ({
  width: "100%",
  height: "48px",
  borderRadius: "5px",
  backgroundColor: disabled ? "" : "#F8FAF8",
  "& .MuiInputAdornment-root svg path": {
    fill: disabled ? "#ADADAD" : "#333",
  },

  "& .MuiInputBase-input": {
    color: disabled ? "#A3A3A3" : "#333",
    fontSize: "24px",
    fontWeight: "700",
    textTransform: "capitalize",
  },
}));

const QuoteInputSlider = styled(Slider)(() => ({
  "& .MuiSlider-rail": {
    color: "#B2C0B2",
    border: "2px solid #fff",
    borderRadius: "15px",
  },

  "& .MuiSlider-track": {
    display: "none",
  },

  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "6px solid #333",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
}));

const StyledBoxContainer = styled(Box)(
  ({ flexDirection, gap, marginBottom }) => ({
    display: "flex",
    flexDirection: flexDirection || "row",
    gap: gap || "0px",
    marginBottom: marginBottom || "0px",
  })
);

export default function EnquiryReQuote(props) {
  const handleSliderChange = (e) => {
    props.handleSliderChange(e.target.value);
  };

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <StyledBoxContainer gap="10px">
        <Box fontWeight="700" sx={{ flex: "1" }}>
          <QuoteInput
            disabled={
              !props.vehicleNumber ||
              props.enquiryStatus === ENQUIRY_STATUS.BID_WON
            }
            startAdornment={
              <InputAdornment position="start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="18"
                  viewBox="0 0 12 18"
                  fill="none"
                >
                  <path
                    d="M0 1.59H4.089C5.919 1.59 7.269 2.4525 7.5765 4.176H0V5.7525H7.5765C7.2825 7.5015 6 8.337 4.182 8.337H0V10.3125L6.6555 18H9.8205L3.1425 10.326H4.425C7.899 10.299 9.6225 8.112 9.957 5.7525H12V4.176H9.9825C9.8625 3.009 9.447 2.1735 8.793 1.578H12V0H0V1.59Z"
                    fill="#A3A3A3"
                  />
                </svg>
              </InputAdornment>
            }
            value={props.quoteAmount || ""}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
          />
        </Box>
        <Button
          variant="text"
          disabled={
            !props.vehicleNumber ||
            props.enquiryStatus === ENQUIRY_STATUS.BID_WON
          }
          sx={{
            height: "48px",
            "&:hover": { backgroundColor: "#0C7F44" },
            backgroundColor:
              !props.vehicleNumber ||
              props.enquiryStatus === ENQUIRY_STATUS.BID_WON
                ? "#CFCFCF"
                : "#0C7F44",
            width: "145px",
          }}
          onClick={props.handleSendQuote}
        >
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "18px",
              fontWeight: "700",
              textTransform: "capitalize",
            }}
          >
            {props.enquiryStatus === "YOU_QUOTED"
              ? "Update Quote"
              : "Send Quote"}
          </Typography>
        </Button>
      </StyledBoxContainer>
      <Box width="100%">
        {props.vehicleNumber && (
          <>
            {props.isEsmtLoading ? (
              <Skeleton variant="rect" height={100} width={150} />
            ) : (
              <>
                {props.estimate?.estimateCode === 4 ? (
                  <>
                    <Box
                      display="flex"
                      justifyItems="center"
                      alignItems="center"
                      width="100%"
                    >
                      <Box paddingRight="20px">
                        <Typography
                          color="#41B31A"
                          fontSize="18px"
                          fontWeight="700"
                        >
                          {`₹ ${Math.ceil(
                            props.estimate?.estimationDetails?.minFare
                          )}`}
                        </Typography>
                      </Box>
                      <Box cursor="pointer" sx={{ flex: "1" }}>
                        <QuoteInputSlider
                          min={Math.ceil(
                            props.estimate?.estimationDetails?.minFare
                          )}
                          max={Math.ceil(
                            props.estimate?.estimationDetails?.maxFare
                          )}
                          step={0.01}
                          defaultValue={props.quoteAmount}
                          onChange={handleSliderChange}
                          value={
                            typeof props.quoteAmount === "number"
                              ? props.quoteAmount
                              : props.estimate?.estimationDetails?.minFare
                          }
                        />
                      </Box>
                      <Box paddingLeft="20px">
                        <Typography
                          color="#FF3F3F"
                          fontSize="18px"
                          fontWeight="700"
                          sx={{ textAlign: "right" }}
                        >
                          {`₹ ${Math.ceil(
                            props.estimate?.estimationDetails?.maxFare
                          )}`}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Typography
                        textTransform="lowercase"
                        width="20%"
                        fontSize="12px"
                      >
                        {"high chance of winning"}
                      </Typography>

                      <Typography
                        textTransform="lowercase"
                        sx={{ textAlign: "right" }}
                        width="20%"
                        fontSize="12px"
                      >
                        {"low chance of winning"}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Box>
                    {props.enquiryStatus === ENQUIRY_STATUS.YOU_QUOTED ? (
                      <Typography sx={{ fontSize: 14, color: "#ff3f3f" }}>
                        **Note - New amount should be less than previous one.
                      </Typography>
                    ) : (
                      <Typography sx={{ color: "#ff3f3f" }}>
                        Sorry ! Estimates not available
                      </Typography>
                    )}
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
