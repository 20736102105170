import React, { useState } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import HeaderWithUnderline from "../Configurations/Enquiries/HeaderWithUnderline";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import TripCompleteModal from "./TripCompleteModal";

const DeliveryProof = ({
  tripData,
  getTripById,
  handleOpenToast,
  enquiryDetails,
  customerAmounts,
  transporterAmounts,
  tripId,
}) => {
  const { PODImages } = tripData || {};

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [showImage, setShowImage] = useState(false);
  const [showCompleteTripModal, setShowCompleteTripModal] = useState(false);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box sx={{ padding: "24px" }}>
      <HeaderWithUnderline header="Delivery Proof" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "30px",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
          <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
            Proof of delivery
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            {!showImage ? (
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 700,
                  cursor: "pointer",
                  color: "#4285F4",
                }}
                onClick={() => setShowImage(!showImage)}
              >
                View Images
              </Typography>
            ) : PODImages?.length > 0 ? (
              PODImages?.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt="POD"
                  style={{
                    width: "80px",
                    height: "50px",
                    gap: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleImageClick(index)}
                />
              ))
            ) : (
              <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                No images found
              </Typography>
            )}
          </Box>
        </Box>
        <StyledButton
          variant="contained"
          color="primary"
          type="submit"
          // onClick={handleSendPaymentAlert}
          onClick={() => setShowCompleteTripModal(true)}
        >
          Mark as Trip Completed
        </StyledButton>
      </Box>
      {isModalOpen && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            zIndex: (theme) => theme.zIndex.drawer + 101,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 100,
            }}
          >
            <CloseIcon />
          </IconButton>

          <Swiper
            modules={[Navigation]}
            spaceBetween={10}
            slidesPerView={1}
            navigation
            initialSlide={selectedImageIndex}
          >
            {PODImages.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image}
                  alt={`Slide ${index}`}
                  style={{
                    maxWidth: "90vw",
                    maxHeight: "90vh",
                    display: "block",
                    margin: "auto",
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      )}

      {showCompleteTripModal && (
        <TripCompleteModal
          enquiryDetails={enquiryDetails}
          customerAmounts={customerAmounts}
          transporterAmounts={transporterAmounts}
          tripId={tripId}
          getTripById={getTripById}
          handleCloseModal={() => setShowCompleteTripModal(false)}
        />
      )}
    </Box>
  );
};

export default DeliveryProof;
const StyledButton = styled(Button)(() => ({
  padding: "7px 20px",
  fontSize: "0.8rem",
  margin: "7px 0",
}));
