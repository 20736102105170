import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import styled from "@emotion/styled";
import HeaderWithUnderline from "../Configurations/Enquiries/HeaderWithUnderline";
import NameDescription from "../Configurations/Enquiries/NameDescription";

import SettlePaymentModal from "./SettlePaymentModal";
import TransactionTable from "./TransactionTable";

import moment from "moment";
import {
  getCustomerTransactions,
  sendPaymentAlert,
} from "../../../api/Admin/trips";

export default function CustomerReceiptDetails({
  tripData,
  getTripById,
  handleOpenToast,
}) {
  const [isSettlePaymentModalOpen, setIsSettlePaymentModalOpen] =
    useState(false);

  const [transactionData, setTransactionData] = useState([]);

  const [showTransaction, setShowTransaction] = useState(false);

  const handleSendPaymentAlert = async () => {
    try {
      await sendPaymentAlert({
        tripId: tripData?.tripId,
        customerName: tripData?.customerDetails?.fullName,
        customerPhone: tripData?.customerDetails?.phone,
        tripDate: moment(tripData?.enquiryDetails?.pickup?.date).format(
          "DD/MM/YYYY"
        ),
        BalanceReceivable: tripData?.customerAmounts?.balanceReceivable,
      });
      handleOpenToast("Payment alert sent successfully", "success");
    } catch (error) {
      handleOpenToast("Error while sending payment alert", "error");
    }
  };

  const handleSettlePaymentCloseModal = () => {
    setIsSettlePaymentModalOpen(false);
    getTripById();
  };

  const getTransactionData = async () => {
    try {
      const response = await getCustomerTransactions(tripData?.tripId);
      setTransactionData(response.data.data);
    } catch (error) {
      handleOpenToast("Error while getting transaction", "error");
    }
  };

  // to check if the deadline is passed for the customer payment
  const isDeadlinePassed = moment().isAfter(
    moment(tripData?.customerPaymentSettlementDeadline)
  );

  useEffect(() => {
    getTransactionData();
  }, [tripData]);

  return (
    <Box sx={{ padding: "24px" }}>
      <HeaderWithUnderline header="Customer Receipt Details" />
      <SettlePaymentModal
        isModalOpen={isSettlePaymentModalOpen}
        handleCloseModal={handleSettlePaymentCloseModal}
        tripData={tripData}
        userType="customer"
      />
      <StyledBox>
        <BoxContainer marginLeft="0px" gap="40px">
          <NameDescription
            name="Total Amount"
            description={
              `₹ ${Math.ceil(tripData?.customerAmounts?.finalAmount)}` || "-"
            }
            color="#6D6D6D"
            marginLeft="0px"
          />
          <NameDescription
            name="Amount Received"
            description={
              `₹ ${Math.ceil(tripData?.customerAmounts?.amountReceived)}` || "-"
            }
            marginLeft="0px"
            color="green"
          />
          {!tripData?.tripStatus?.includes("CANCELLED") && (
            <NameDescription
              name="Balance Receivable"
              description={
                `₹ ${Math.ceil(
                  tripData?.customerAmounts?.balanceReceivable
                )}` || "-"
              }
              marginLeft="0px"
              color={
                tripData?.customerAmounts?.balanceReceivable > 0
                  ? "red"
                  : "green"
              }
            />
          )}

          {tripData?.customerAmounts?.finalAmount >
            tripData?.customerAmounts?.amountReceived &&
            !tripData?.tripStatus.includes("CANCELLED") &&
            isDeadlinePassed && (
              <StyledButton
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSendPaymentAlert}
              >
                Send Payment Alert
              </StyledButton>
            )}

          {tripData?.customerAmounts?.finalAmount >
            tripData?.customerAmounts?.amountReceived &&
            !tripData?.tripStatus.includes("CANCELLED") && (
              <>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={() => setIsSettlePaymentModalOpen(true)}
                >
                  Settle Customer Payment
                </StyledButton>
              </>
            )}
        </BoxContainer>
        <StyledTypography
          color="#3F51B5"
          onClick={() => setShowTransaction(!showTransaction)}
        >
          {showTransaction ? "Hide Transaction" : "Show Transaction"}
        </StyledTypography>
      </StyledBox>
      {showTransaction && (
        <StyledTransactionBox>
          <TransactionTable
            transactionData={transactionData}
            name={tripData?.customerDetails?.fullName}
          />
        </StyledTransactionBox>
      )}
    </Box>
  );
}

const StyledButton = styled(Button)(() => ({
  padding: "7px 20px",
  fontSize: "0.8rem",
  margin: "7px 0",
}));

const StyledTransactionBox = styled(Box)(() => ({
  marginTop: "32px",
  display: "flex",
  flexDirection: "row",
}));

const BoxContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  paddingTop: "16px",
  paddingLeft: "32px",
  paddingRight: "32px",
}));

const StyledBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledTypography = styled(Typography)(({ color }) => ({
  display: "inline",
  color: color || "#FF3F3F",
  fontSize: "14px",
  fonWeight: "700",
  marginLeft: "40px",
  cursor: "pointer",
  textDecoration: "underline",
}));
