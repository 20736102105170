import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Typography } from "@mui/material";
import { DialogBtn } from "../Common/DialogBtn";
import { CustomTextInput } from "../Common/CustomTextInput";
import validator from "validator";
import { SAVING_STATUS } from "../../constants/constant";
import { updateContactPersonDetails } from "../../api/Customer/enquiry";
import { useSelector, useDispatch } from "react-redux";
import { setBookingStatusTracking } from "../../redux/slices/customerSlice";
import useToast from "../../hooks/useToast";

export default function ContactDialog({ open, handleClose, tripDetails }) {
  const dispatch = useDispatch();
  const { handleOpenToast, ToastMessage } = useToast();
  const customerId = useSelector((state) => state.customer.id);
  const [pickupContactPersonName, setPickupContactPersonName] = useState("");
  const [pickupContactPersonNumber, setPickupContactPersonNumber] =
    useState("");
  const [dropOffContactPersonName, setDropOffContactPersonName] = useState("");
  const [dropOffContactPersonNumber, setDropOffContactPersonNumber] =
    useState("");
  const [pickupCPNameError, setPickupCPNameError] = useState("");
  const [pickupCPNumberError, setPickupCPNumberError] = useState("");
  const [dropoffCPNameError, setDropoffCPNameError] = useState("");
  const [dropOffCPNumberError, setDropOffCPNumberError] = useState("");
  const [isContactDetailsUploaded, setIsContactDetailsUploaded] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorMessage = "Field cannot be empty";

    let valid = true;

    if (!pickupContactPersonName) {
      setPickupCPNameError(errorMessage);
      valid = false;
    } else {
      setPickupCPNameError("");
    }

    if (!pickupContactPersonNumber) {
      setPickupCPNumberError(errorMessage);
      valid = false;
    } else {
      setPickupCPNumberError("");
    }

    if (!dropOffContactPersonName) {
      setDropoffCPNameError(errorMessage);
      valid = false;
    } else {
      setDropoffCPNameError("");
    }

    if (!dropOffContactPersonNumber) {
      setDropOffCPNumberError(errorMessage);
      valid = false;
    } else {
      setDropOffCPNumberError("");
    }

    if (!valid) {
      return;
    }

    const ContactPersonDetails = {
      pickupContactPerson: {
        name: pickupContactPersonName,
        phoneNumber: pickupContactPersonNumber,
      },
      dropOffContactPerson: {
        name: dropOffContactPersonName,
        phoneNumber: dropOffContactPersonNumber,
      },
    };

    if (tripDetails?.id) {
      setIsContactDetailsUploaded(SAVING_STATUS.SENDING);
      const response = await updateContactPersonDetails(
        customerId,
        tripDetails?.id,
        ContactPersonDetails
      );
      if (response.status === 200) {
        handleClose();
        setIsContactDetailsUploaded(SAVING_STATUS.SAVED);
        handleOpenToast(
          "Contact person details saved successfully.",
          "success"
        );

        new Promise((resolve) => setTimeout(resolve, 3000)).then(() => {
          dispatch(
            setBookingStatusTracking(`contact-details-saved-${Date.now()}`)
          );
        });
      } else {
        handleClose();
        setIsContactDetailsUploaded(SAVING_STATUS.FAILED);
        handleOpenToast(`Error saving contact person details.`, "error");
      }
    } else {
      handleClose();
      setIsContactDetailsUploaded(SAVING_STATUS.FAILED);
      handleOpenToast(`Something went wrong.`, "error");
    }
  };

  const handleContactName = (e, setField, setError) => {
    var value = e.target.value;
    setField(value);
    if (value.length > 50) {
      setError("Name should not exceed 50 characters");
    } else {
      setError("");
    }
  };
  const handleContactNumber = (e, setField, setError) => {
    var value = e.target.value;
    value = value.replace(/[^0-9]/g, "");
    setField(value);

    if (
      value.trim() !== "" &&
      !validator.isMobilePhone(value, "any", { strictMode: false })
    ) {
      setError("Invalid contact number");
    } else {
      setError("");
    }
  };

  const setContactDetails = () => {
    if (Object.keys(tripDetails?.pickupContactPerson).length !== 0) {
      setPickupContactPersonName(tripDetails?.pickupContactPerson?.name);
      setPickupContactPersonNumber(
        tripDetails?.pickupContactPerson?.phoneNumber
      );
    }
    if (Object.keys(tripDetails?.pickupContactPerson).length !== 0) {
      setDropOffContactPersonName(tripDetails?.dropOffContactPerson?.name);
      setDropOffContactPersonNumber(
        tripDetails?.dropOffContactPerson?.phoneNumber
      );
    }
  };
  useEffect(() => {
    setContactDetails();
  }, []);
  return (
    <React.Fragment>
      <ToastMessage />
      <Dialog
        maxWidth={650}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          overflow: "hidden",
        }}
      >
        <Box sx={{ width: "650px", overflow: "hidden" }} p={3}>
          <Box display="flex" style={{ justifyContent: "space-between" }}>
            <Box>
              <Typography
                sx={{ fontSize: 24, fontWeight: 700, color: "#333333" }}
              >
                {Object.keys(tripDetails?.pickupContactPerson).length !== 0
                  ? "Edit Contact Details"
                  : "Add Contact Details"}
              </Typography>
              <Typography
                sx={{ fontSize: 14, fontWeight: 400, color: "#808080" }}
              >
                Sender and Receiver Contact Information
              </Typography>
            </Box>
            <Box
              onClick={() => handleClose()}
              style={{ cursor: "pointer", pt: 3 }}
            >
              <img src="/assets/icons/cross-icon.svg" alt="close" />
            </Box>
          </Box>

          <form onSubmit={handleSubmit}>
            <Box>
              <Box display="flex" justifyContent="space-between" mt={3} gap={1}>
                <CustomTextInput
                  label="Pickup Contact Person Name"
                  handleInputChange={(e) =>
                    handleContactName(
                      e,
                      setPickupContactPersonName,
                      setPickupCPNameError
                    )
                  }
                  value={pickupContactPersonName}
                  error={pickupCPNameError}
                  containerStyle={{ flexGrow: 1 }}
                  mandatory
                />
                <CustomTextInput
                  label="Pickup contact person's mobile number"
                  handleInputChange={(e) =>
                    handleContactNumber(
                      e,
                      setPickupContactPersonNumber,
                      setPickupCPNumberError
                    )
                  }
                  value={pickupContactPersonNumber}
                  error={pickupCPNumberError}
                  mandatory
                />
              </Box>
              <Box display="flex" justifyContent="space-between" mt={2} gap={1}>
                <CustomTextInput
                  label="Drop-Off Contact Person Name"
                  handleInputChange={(e) =>
                    handleContactName(
                      e,
                      setDropOffContactPersonName,
                      setDropoffCPNameError
                    )
                  }
                  value={dropOffContactPersonName}
                  error={dropoffCPNameError}
                  containerStyle={{ flexGrow: 1 }}
                  mandatory
                />
                <CustomTextInput
                  label="Drop-Off contact person's mobile number"
                  handleInputChange={(e) =>
                    handleContactNumber(
                      e,
                      setDropOffContactPersonNumber,
                      setDropOffCPNumberError
                    )
                  }
                  value={dropOffContactPersonNumber}
                  error={dropOffCPNumberError}
                  containerStyle={{ flexGrow: 1 }}
                  mandatory
                />
              </Box>
            </Box>

            <Box
              sx={{
                textAlign: "center",
                marginTop: 3,
              }}
            >
              <DialogBtn
                type="submit"
                title={
                  Object.keys(tripDetails?.pickupContactPerson).length !== 0
                    ? "Edit"
                    : "Add"
                }
                buttonStyle={{
                  color: "#FFFFFF",
                  background: "#0C7F44",
                  "&:hover": {
                    backgroundColor: "#0C7F44",
                    opacity: 0.8,
                  },
                }}
                disabled={isContactDetailsUploaded === SAVING_STATUS.SENDING}
              />
            </Box>
          </form>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
